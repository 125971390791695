import React from 'react';
import { Table } from 'reactstrap';
// import PropTypes from 'prop-types';

const BookingsList = () => (
  <div>
    <Table className="table--bordered" responsive>
      <thead>
        <tr>
          <th>Time</th>
          <th>Edit</th>
          <th>Hist</th>
          <th>Status</th>
          <th>Booking</th>
          <th>AccNo</th>
          <th>AS</th>
          <th>FileNo</th>
          <th>Patient</th>
          <th>PS</th>
          <th>CellNo</th>
          <th>Reason</th>
          <th>Scheme</th>
          <th>NB</th>
          <th>SMS</th>
          <th>INV</th>
          <th>CN</th>
          <th>BAL</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>07:00</td>
          <td>Edit</td>
          <td>Hist</td>
          <td>Status</td>
          <td>Booking</td>
          <td>AccNo</td>
          <td>AS</td>
          <td>FileNo</td>
          <td>Patient</td>
          <td>PS</td>
          <td>CellNo</td>
          <td>Reason</td>
          <td>Scheme</td>
          <td>NB</td>
          <td>SMS</td>
          <td>INV</td>
          <td>CN</td>
          <td>BAL</td>
        </tr>
      </tbody>
    </Table>
  </div>
);

export default BookingsList;
