import axios from 'axios';
import {
  AUTH_SIGN_IN_USER_DATA,
  AUTH_SIGN_OUT,
  AUTH_REFRESH_TOKEN
} from '../types';
// import { addToasterMessage } from './toaster.actions';
import { URLControlAPIAuthSignIn } from '../apis/auth';

//
//
// SIGN IN
export const signIn = (formValues) => async (dispatch) => {
  const authHeaders = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  axios
    .post(URLControlAPIAuthSignIn(), JSON.stringify(formValues), authHeaders)
    .then((res) => {
      const { accessToken, fullName, refreshToken } = res?.data || {};
      dispatch({
        type: AUTH_SIGN_IN_USER_DATA,
        payload: {
          accessToken,
          fullName,
          refreshToken
        }
      });
    })
    .catch(() => {
      // dispatch(addToasterMessage({ messagePath: 'auth.signIn.error' }));
    });
};

//
//
// SIGN OFF
export const signOff = () => async (dispatch) => {
  const authHeaders = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  axios
    .get(URLControlAPIAuthSignIn(), authHeaders)
    .then(() => {
      dispatch({
        type: AUTH_SIGN_OUT
      });
    })
    .catch(() => {
      // dispatch(addToasterMessage({ messagePath: 'auth.signOff.error' }));
    });
};

//
//
// SIGN OFF
export const getNewToken = () => async (dispatch, getState) => {
  const { refreshToken } = getState().auth;
  const authHeaders = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const payload = { token: refreshToken };

  axios
    .post(URLControlAPIAuthSignIn(), JSON.stringify(payload), authHeaders)
    .then((res) => {
      const { accessToken, fullName, refreshToken: newRefreshToken } =
        res?.data || {};
      dispatch({
        type: AUTH_REFRESH_TOKEN,
        payload: {
          accessToken,
          fullName,
          refreshToken: newRefreshToken
        }
      });
    })
    .catch(() => {
      // dispatch(addToasterMessage({ messagePath: 'auth.signIn.error' }));
    });
};
