import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { Button } from 'reactstrap';
import { siteMapObj } from '../../../sitemap.js';
import { Link } from 'react-router-dom';

const SignInComponent = () => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    console.log('---');
  };

  const handleOnSubmit = (values) => {
    // props.signIn(values);
  };

  const signInPagePath = siteMapObj?.signIn?.path;
  const renderLinkToSignIn = signInPagePath && (
    <Link to={signInPagePath}>Sign in</Link>
  );

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">Create account</h3>
          </div>
          <Form
            onSubmit={handleOnSubmit}
            render={({ handleSubmit }) => (
              <form className="form vertical" onSubmit={handleSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Name</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <AccountOutlineIcon />
                    </div>
                    <Field
                      component="input"
                      name="name"
                      placeholder="Name"
                      type="text"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Email</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <AccountOutlineIcon />
                    </div>
                    <Field
                      component="input"
                      name="email"
                      placeholder="Email"
                      type="text"
                    />
                  </div>
                </div>
                <div className="form__form-group form__form-group--forgot">
                  <span className="form__form-group-label">Password</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <AlternateEmailIcon />
                    </div>
                    <Field
                      className="input-without-border-radius"
                      component="input"
                      name="password"
                      placeholder="Password"
                      required
                      type={showPassword ? 'text' : 'password'}
                    />
                    <button
                      type="button"
                      className={`form__form-group-button${
                        showPassword ? ' active' : ''
                      }`}
                      onClick={toggleShowPassword}>
                      <EyeIcon />
                    </button>
                  </div>
                </div>
                <div className="account__btns register__btns">
                  <Button
                    type="submit"
                    color="success"
                    className="account__btn">
                    Sign in
                  </Button>
                </div>
              </form>
            )}
          />
          {renderLinkToSignIn}
        </div>
      </div>
    </div>
  );
};

SignInComponent.propTypes = {};

const mapStateToProps = ({ auth }) => {
  const { isAuth } = auth;

  return { isAuth };
};

export default connect(mapStateToProps, {})(SignInComponent);
