// sitemap - list of pages
// import Auth from './Auth';
import CreateAccount from './Auth/Pages/CreateAccount';
import Bookings from './Bookings';
import ExternalDoctors from './ExternalDoctors';
import ForgotPassword from './Auth/Pages/ForgotPassword';
import Home from './Home';
import SignIn from './Auth/Pages/SignIn';

const siteMap = [
  {
    componentName: SignIn,
    exact: true,
    id: 'auth',
    isPrivate: false,
    path: '/auth'
  },
  {
    componentName: SignIn,
    exact: true,
    id: 'signIn',
    isPrivate: false,
    path: '/auth/sign-in'
  },
  {
    componentName: CreateAccount,
    exact: true,
    id: 'createAccount',
    isPrivate: false,
    path: '/auth/create-account'
  },
  {
    componentName: ForgotPassword,
    exact: true,
    id: 'forgotPassword',
    isPrivate: false,
    path: '/auth/forgot-password'
  },
  {
    componentName: Home,
    exact: true,
    id: 'home',
    isPrivate: false,
    path: '/'
  },
  {
    componentName: ExternalDoctors,
    exact: true,
    id: 'externalDoctors',
    isPrivate: false,
    path: '/external-doctors'
  },
  {
    componentName: Bookings,
    exact: true,
    id: 'bookings',
    isPrivate: false,
    path: '/bookings'
  }
];

let siteMapObj = {};
siteMap.forEach((x) => {
  siteMapObj[x.id] = x;
});

export default siteMap;
export { siteMapObj };
