import {
  AUTH,
  AUTH_SIGN_IN_USER_DATA,
  AUTH_SIGN_OUT
} from '../types';

const fullName = localStorage.getItem('fullName') || '';
const refreshToken = localStorage.getItem('refreshToken') || null;

const INITIAL_STATE = {
  accessToken: null,
  isAuthLoaded: false,
  fullName,
  refreshToken
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH: {
      return {
        ...state
      };
    }
    case AUTH_SIGN_IN_USER_DATA: {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        fullName: action.payload.fullName,
        refreshToken: action.payload.refreshToken
      };
    }
    case AUTH_SIGN_OUT: {
      return {
        ...state,
        accessToken: null,
        fullName: null,
        refreshToken: null
      };
    }
    default:
      return state;
  }
};
