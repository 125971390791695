import {
  EXTERNAL_DOCTORS_IS_ADD_APPOINTMENT_MODAL_OPEN,
  EXTERNAL_DOCTORS_IS_SEND_SMS_MODAL_OPEN
} from '../types';


const INITIAL_STATE = {
  isExternalDoctorsAddAppointmentModalOpen: false,
  isExternalDoctorsSendSMSModalOpen: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case EXTERNAL_DOCTORS_IS_ADD_APPOINTMENT_MODAL_OPEN: {
      return {
        ...state,
        isExternalDoctorsAddAppointmentModalOpen: action.isOpen
      };
    }

    case EXTERNAL_DOCTORS_IS_SEND_SMS_MODAL_OPEN: {
      return {
        ...state,
        isExternalDoctorsSendSMSModalOpen: action.isOpen
      };
    }

    default:
      return state;
  }
};
