/* eslint-disable no-underscore-dangle */
import { applyMiddleware, createStore, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import JSONSchema from '../reducers';

const store = createStore(
  JSONSchema,
  {},
  compose(
    applyMiddleware(ReduxThunk),
    typeof window === 'object' &&
      // typeof window.devToolsExtension !== 'undefined'
      typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

export default store;
