// import axios from 'axios';
import {
  EXTERNAL_DOCTORS_IS_ADD_APPOINTMENT_MODAL_OPEN
  // EXTERNAL_DOCTORS_IS_SEND_SMS_MODAL_OPEN
} from '../types';

//
//
// CLOSE MODAL EXTERNAL DOCTOR APPOINTMENT
export const closeExternalDoctorAppointmentModal = () => ({
  type: EXTERNAL_DOCTORS_IS_ADD_APPOINTMENT_MODAL_OPEN,
  isOpen: false
});

//
//
// OPEN MODAL EXTERNAL DOCTOR APPOINTMENT
export const openExternalDoctorAppointmentModal = () => ({
  type: EXTERNAL_DOCTORS_IS_ADD_APPOINTMENT_MODAL_OPEN,
  isOpen: true
});

/*

//
//
// CLOSE MODAL SEND SMS
export const closeExternalDoctorSendSMSModal = () => ({
  type: EXTERNAL_DOCTORS_IS_SEND_SMS_MODAL_OPEN,
  isOpen: false
});

//
//
// OPEN MODAL SEND SMS
export const openExternalDoctorSendSMSModal = () => ({
  type: EXTERNAL_DOCTORS_IS_SEND_SMS_MODAL_OPEN,
  isOpen: true
});
 */
