import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FormExample from './Component/FomExample';

const Home = () => (
  <div>
    <h1>Home page </h1>

    <br />

    <p>
      <Link to="/external-doctors">LINK external doctors page </Link>
    </p>

    {/*<FormExample />*/}
  </div>
);

export default Home;
