import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { Button } from 'reactstrap';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
// import { siteMapObj } from '../../../sitemap';
/*
import { BrowserRouter as Router,
Switch,
Route,
Link,
Redirect,
useHistory,
useLocation } from
'react-router-dom';
 */
// import InputField from '../../../../Components/FormComponents/InputField';
import { signIn } from '../../../../actions/auth.actions';
import { siteMapObj } from '../../../sitemap.js';

const SignInComponent = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleOnSubmit = (values) => {
    props.signIn(values);
  };

  const createAccountPagePath = siteMapObj?.createAccount?.path;
  const renderLinkToCreateAccountPage = createAccountPagePath && (
    <Link to={createAccountPagePath}>Create account</Link>
  );

  const forgotPasswordPagePath = siteMapObj?.forgotPassword?.path;
  const renderLinkToForgotPasswordPage = forgotPasswordPagePath && (
    <Link to={forgotPasswordPagePath}>Forgot password</Link>
  );

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">Sign in</h3>
          </div>
          <Form
            initialValues={{
              username: '',
              password: ''
            }}
            onSubmit={handleOnSubmit}
            render={({ handleSubmit }) => (
              <form className="form vertical" onSubmit={handleSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Email</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <AccountOutlineIcon />
                    </div>
                    <Field
                      component="input"
                      name="email"
                      placeholder="Default Input"
                      type="text"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Password</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <AlternateEmailIcon />
                    </div>
                    <Field
                      className="input-without-border-radius"
                      component="input"
                      name="password"
                      placeholder="Password"
                      type={showPassword ? 'text' : 'password'}
                    />
                    <button
                      className={`form__form-group-button${
                        showPassword ? ' active' : ''
                      }`}
                      onClick={toggleShowPassword}
                      type="button">
                      <EyeIcon />
                    </button>
                  </div>
                </div>
                <div className="form__form-group form__form-group-with-cols">
                  <div className="form__form-group-with-cols">
                    <div className="form__form-group-field">
                      <Field
                        component={renderCheckBoxField}
                        defaultChecked={false}
                        label="Remember me"
                        name="rememberMe"
                        type="checkbox"
                        // className={className}
                      />
                    </div>
                  </div>
                  <div className="form__form-group-with-cols">
                    {renderLinkToForgotPasswordPage}
                  </div>
                </div>
                <div className="account__btns register__btns">
                  <Button
                    className="account__btn"
                    type="submit"
                    color="success">
                    Sign in
                  </Button>
                </div>
              </form>
            )}
          />
          {renderLinkToCreateAccountPage}
        </div>
      </div>
    </div>
  );
};

SignInComponent.propTypes = {
  signIn: PropTypes.func
};

const mapStateToProps = ({ auth }) => {
  const { isAuth } = auth;

  return { isAuth };
};

export default connect(mapStateToProps, { signIn })(SignInComponent);
