
// *******************
// *******************
// AUTH
export const AUTH = 'AUTH';
export const AUTH_SIGN_IN_USER_DATA = 'AUTH_SIGN_IN_USER_DATA';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';

// *******************
// *******************
// EXTERNAL DOCTORS
export const EXTERNAL_DOCTORS_IS_ADD_APPOINTMENT_MODAL_OPEN = 'EXTERNAL_DOCTORS_IS_ADD_APPOINTMENT_MODAL_OPEN';
export const EXTERNAL_DOCTORS_IS_SEND_SMS_MODAL_OPEN = 'EXTERNAL_DOCTORS_IS_SEND_SMS_MODAL_OPEN';

// *******************
// *******************
// THEATRE BOOKING
