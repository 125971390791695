import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const Header = (props) => {
  const {} = props;

  return (
    <div className="topbar">
      <div className="topbar__left">
        <Link className="topbar__logo" to="/">
          <svg
            fill="none"
            height="19"
            viewBox="0 0 91 19"
            width="91"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.23998 0.869995C11.44 0.869995 13.29 1.49 14.79 2.73C16.31 3.97 17.27 5.69 17.67 7.89H10.62C10.36 7.01 9.84998 6.57 9.08998 6.57C8.54998 6.57 8.11998 6.82 7.79998 7.32C7.49998 7.82 7.34998 8.56 7.34998 9.54C7.34998 10.52 7.49998 11.26 7.79998 11.76C8.11998 12.26 8.54998 12.51 9.08998 12.51C9.84998 12.51 10.36 12.07 10.62 11.19H17.67C17.27 13.39 16.31 15.11 14.79 16.35C13.29 17.59 11.44 18.21 9.23998 18.21C7.55998 18.21 6.05998 17.86 4.73998 17.16C3.43998 16.46 2.41998 15.46 1.67998 14.16C0.959976 12.84 0.599976 11.3 0.599976 9.54C0.599976 7.78 0.959976 6.25 1.67998 4.95C2.41998 3.63 3.43998 2.62 4.73998 1.92C6.05998 1.22 7.55998 0.869995 9.23998 0.869995Z"
              fill="#00A62E"
            />
            <path
              d="M36.1612 9.39C36.1612 9.81 36.1112 10.25 36.0112 10.71H25.3612C25.4212 11.55 25.6112 12.13 25.9312 12.45C26.2512 12.75 26.6712 12.9 27.1912 12.9C27.9112 12.9 28.4312 12.57 28.7512 11.91H35.8012C35.5612 13.11 35.0512 14.19 34.2712 15.15C33.5112 16.09 32.5412 16.84 31.3612 17.4C30.2012 17.94 28.9212 18.21 27.5212 18.21C25.8412 18.21 24.3412 17.86 23.0212 17.16C21.7212 16.46 20.7012 15.46 19.9612 14.16C19.2412 12.84 18.8812 11.3 18.8812 9.54C18.8812 7.78 19.2412 6.25 19.9612 4.95C20.7012 3.63 21.7212 2.62 23.0212 1.92C24.3412 1.22 25.8412 0.869995 27.5212 0.869995C29.2012 0.869995 30.6912 1.22 31.9912 1.92C33.3112 2.6 34.3312 3.58 35.0512 4.86C35.7912 6.14 36.1612 7.65 36.1612 9.39ZM29.4112 7.98C29.4112 7.34 29.2312 6.87 28.8712 6.57C28.5112 6.25 28.0612 6.09 27.5212 6.09C26.3012 6.09 25.6012 6.72 25.4212 7.98H29.4112Z"
              fill="#00A62E"
            />
            <path
              d="M44.9276 4.2C45.6076 3.22 46.4376 2.44 47.4176 1.85999C48.3976 1.26 49.4176 0.959996 50.4776 0.959996V8.07H48.5876C47.3276 8.07 46.3976 8.29 45.7976 8.73C45.2176 9.17 44.9276 9.95 44.9276 11.07V18H38.2676V1.08H44.9276V4.2Z"
              fill="#00A62E"
            />
            <path
              d="M64.1215 0.959996C66.0615 0.959996 67.5715 1.61 68.6515 2.91C69.7515 4.21 70.3015 5.97 70.3015 8.19V18H63.6715V9.03C63.6715 8.23 63.4515 7.6 63.0115 7.14C62.5915 6.66 62.0215 6.42 61.3015 6.42C60.5615 6.42 59.9815 6.66 59.5615 7.14C59.1415 7.6 58.9315 8.23 58.9315 9.03V18H52.2715V1.08H58.9315V3.66C59.4515 2.86 60.1415 2.21 61.0015 1.71C61.8815 1.21 62.9215 0.959996 64.1215 0.959996Z"
              fill="#00A62E"
            />
            <path
              d="M79.2794 0.869995C80.3794 0.869995 81.3294 1.1 82.1294 1.56C82.9294 2 83.5394 2.61 83.9594 3.39V1.08H90.5894V18H83.9594V15.69C83.5394 16.47 82.9294 17.09 82.1294 17.55C81.3294 17.99 80.3794 18.21 79.2794 18.21C77.9594 18.21 76.7694 17.87 75.7094 17.19C74.6494 16.49 73.8094 15.49 73.1894 14.19C72.5894 12.87 72.2894 11.32 72.2894 9.54C72.2894 7.76 72.5894 6.22 73.1894 4.92C73.8094 3.6 74.6494 2.6 75.7094 1.92C76.7694 1.22 77.9594 0.869995 79.2794 0.869995ZM81.4994 6.69C80.7794 6.69 80.1894 6.94 79.7294 7.44C79.2694 7.94 79.0394 8.64 79.0394 9.54C79.0394 10.44 79.2694 11.14 79.7294 11.64C80.1894 12.14 80.7794 12.39 81.4994 12.39C82.2194 12.39 82.8094 12.14 83.2694 11.64C83.7294 11.14 83.9594 10.44 83.9594 9.54C83.9594 8.64 83.7294 7.94 83.2694 7.44C82.8094 6.94 82.2194 6.69 81.4994 6.69Z"
              fill="#00A62E"
            />
          </svg>
        </Link>
      </div>
      <div className="topbar__right">d2222</div>
    </div>
  );
};

Header.propTypes = {};

const mapStateToProps = ({ auth }) => {
  const {} = auth;

  return {};
};

export default connect(mapStateToProps, {})(Header);
