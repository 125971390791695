import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import FileExcelBoxIcon from 'mdi-react/FileExcelBoxIcon';
import FilePDFBoxIcon from 'mdi-react/FilePDFBoxIcon';
import Select from 'react-select';

const optionsDoctors = [
  {
    label: 'Dr M Swart',
    value: 'aa12'
  },
  {
    label: 'Dr Nobody',
    value: 'a2'
  }
];
const serviceCenter = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Center1',
    value: 'c1'
  }
];

const Sidebar = () => {
  const [startDate, setStartDate] = useState(new Date());

  console.log('start data', startDate);

  return (
    <div>
      <DatePicker
        inline
        onChange={(date) => setStartDate(date)}
        selected={startDate}
      />

      <div className="form__form-group">
        <span className="form__form-group-label">Doctor</span>
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={optionsDoctors[0]}
          options={optionsDoctors}
        />
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Service Centre</span>
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={serviceCenter[0]}
          options={serviceCenter}
        />
      </div>

      <div className="form__form-group form__form-group-no-bottom-margin">
        <span className="form__form-group-label">Day Sheet</span>
        <div className="form__form-group-field">
          <Button className="button--color-grey-green" type="button">
            <FilePDFBoxIcon size={24} /> PDF
          </Button>

          <Button className="button--color-grey-green" type="button">
            <FileExcelBoxIcon size={24} /> PDF
          </Button>
        </div>
      </div>

      <div className="form__form-group form__form-group-no-bottom-margin">
        <span className="form__form-group-label">Condensed</span>
        <div className="form__form-group-field">
          <Button className="button--color-grey-green" type="button">
            <FilePDFBoxIcon size={24} /> PDF
          </Button>

          <Button className="button--color-grey-green" type="button">
            <FileExcelBoxIcon size={24} /> PDF
          </Button>
        </div>
      </div>

      <div className="form__form-group form__form-group-no-bottom-margin">
        <div className="form__form-group-field">
          <Button
            className="button--color-grey-green button--full-width"
            type="button">
            <FilePDFBoxIcon size={24} /> PDF
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
