import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BookingsList from './Components/BookingsList';
import MenuUtils from './Components/MenuUtils';
import Sidebar from './Components/Sidebar';

const Bookings = () => {
  return (
    <div className="container__wrap">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <MenuUtils />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-9 col-lg-9">
                    <h1>
                      Wednesday / <span>Mar 28, 2021</span>
                    </h1>
                    <BookingsList />
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <Sidebar />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Bookings.propTypes = {
  signIn: PropTypes.func
};

const mapStateToProps = ({ auth }) => {
  const { isAuth } = auth;

  return { isAuth };
};

export default connect(mapStateToProps, {})(Bookings);
