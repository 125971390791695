import { combineReducers } from 'redux';
import auth from './auth.reducers';
import externalDoctors from './externalDoctors.reducers';
import theatreBooking from './theatreBooking.main.reducers';


export default combineReducers({
  auth,
  externalDoctors,
  theatreBooking
});
