import React from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import { Button } from 'reactstrap';
import { siteMapObj } from '../../../sitemap';
import { Link } from 'react-router-dom';

const SignInComponent = () => {
  const handleOnSubmit = (values) => {
    // props.signIn(values);
  };

  const signInPagePath = siteMapObj?.signIn?.path;
  const renderLinkToSignIn = signInPagePath && (
    <Link to={signInPagePath}>Sign in</Link>
  );

  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">Forgot password</h3>
          </div>
          <Form
            initialValues={{
              username: '',
              password: ''
            }}
            onSubmit={handleOnSubmit}
            render={({ handleSubmit }) => (
              <form className="form vertical" onSubmit={handleSubmit}>
                <div className="form__form-group form__form-group-margin-bottom">
                  <span className="form__form-group-label">Email</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <AccountOutlineIcon />
                    </div>
                    <Field
                      component="input"
                      name="email"
                      placeholder="Email"
                      type="text"
                    />
                  </div>
                </div>
                <div className="account__btns register__btns">
                  <Button
                    type="submit"
                    color="success"
                    className="account__btn">
                    Recover password
                  </Button>
                </div>
              </form>
            )}
          />
          {renderLinkToSignIn}
        </div>
      </div>
    </div>
  );
};

SignInComponent.propTypes = {};

const mapStateToProps = ({ auth }) => {
  const { isAuth } = auth;

  return { isAuth };
};

export default connect(mapStateToProps, {})(SignInComponent);
