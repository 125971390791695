import React, { Suspense } from 'react';
// import PropTypes from 'prop-types'
import { Route, Switch, withRouter } from 'react-router-dom';
import PrivateRoute from './Components/PrivateRoute';
import Header from './Components/Header';
import NotFound404 from './Pages/NotFound404';
import sitemap from './Pages/sitemap';

const App = () => {
  const renderPage = sitemap.map((x) => {
    const PageComponent = x.componentName;

    if (!PageComponent) return null;

    const RouteType = x.isPrivate ? PrivateRoute : Route;

    return (
      <RouteType
        key={x.id}
        component={PageComponent}
        exact={x.exact}
        path={x.path}
      />
    );
  });

  return (
    <div className="ltr-support">
      {/* <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}></svg> */}
      <Header />
      <Suspense fallback={<div>....</div>}>
        <Switch>
          {/* <Route exact path="/auth" component={Auth} /> */}

          {renderPage}
          <Route path="*">
            <NotFound404 />
          </Route>
        </Switch>
      </Suspense>
    </div>
  );
};

export default withRouter(App);
