import React from 'react';
import { render } from 'react-dom';
import { HashRouter as Router } from 'react-router-dom'; // BrowserRouter, HashRouter
import { Provider } from 'react-redux';
// import 'bootstrap/dist/css/bootstrap.min.css';
import AppHotReload from './AppHotReload';
import store from './store';
import './scss/bootstrap.css';
import './scss/app.scss';

const TachyonComponent = () => (
  <Provider store={store}>
    <Router>
      <AppHotReload />
    </Router>
  </Provider>
);

render(<TachyonComponent />, document.getElementById('app'));
