import React from 'react';
// import PropTypes from 'prop-types';

const MenuUtils = () => (
  <div className="card">
    <div className="card-body">sad</div>
  </div>
);

export default MenuUtils;
