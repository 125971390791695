import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import ModalComponent from '../../Components/ModalComponent';
import { openExternalDoctorAppointmentModal } from '../../actions/externalDoctors.main.actions';

const ExternalDoctors = (props) => {
  const { isExternalDoctorsAddAppointmentModalOpen } = props;

  const handleOnClickOpenExternalDoctorAppointmentModal = () => {
    props.openExternalDoctorAppointmentModal();
  };

  const renderModalComponent = isExternalDoctorsAddAppointmentModalOpen && (
    <div>render if true</div>
  );

  console.log(
    'isExternalDoctorsAddAppointmentModalOpen ',
    isExternalDoctorsAddAppointmentModalOpen
  );

  return (
    <div>
      <h1>ExternalDoctors page</h1>
      <p>
        <button
          onClick={handleOnClickOpenExternalDoctorAppointmentModal}
          type="button">
          open modal appointment
        </button>
      </p>
      {renderModalComponent}
    </div>
  );
};

ExternalDoctors.propTypes = {
  isExternalDoctorsAddAppointmentModalOpen: PropTypes.bool,
  openExternalDoctorAppointmentModal: PropTypes.func
};

const mapStateToProps = ({ externalDoctors }) => {
  const { isExternalDoctorsAddAppointmentModalOpen } = externalDoctors.main;

  return { isExternalDoctorsAddAppointmentModalOpen };
};

export default connect(mapStateToProps, { openExternalDoctorAppointmentModal })(
  ExternalDoctors
);
