import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PrivateRoute = (props) => {
  const { accessToken, isAuthLoaded, children, ...rest } = props;

  const renderRedirect = (location) => (
    <Redirect
      to={{
        pathname: '/auth',
        state: { from: location }
      }}
    />
  );

  const renderRoute = ({ location }) =>
    isAuthLoaded && accessToken ? children : renderRedirect(location);

  if (!isAuthLoaded) return null;
  if (isAuthLoaded && !accessToken) {
    return (
      <Redirect
        to={{
          pathname: '/auth'
        }}
      />
    );
  }

  return (
    <Route
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
      render={({ location }) =>
        isAuthLoaded && accessToken ? children : renderRoute({ location })
      }
    />
  );
};

PrivateRoute.propTypes = {
  accessToken: PropTypes.string,
  children: PropTypes.node,
  isAuthLoaded: PropTypes.bool
};

const mapStateToProps = ({ auth }) => {
  const { accessToken, isAuthLoaded } = auth.main;

  return { accessToken, isAuthLoaded };
};

export default connect(mapStateToProps, {})(PrivateRoute);
