import React from 'react';
// import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import { Field, Form } from 'react-final-form';

const FomExample = () => {
  const handleOnSubmit = (values) => {
    console.log('values ', values);
  };

  return (
    <Modal isOpen /* ={false} */>
      <Form
        initialValues={{
          username: '',
          password: ''
        }}
        onSubmit={handleOnSubmit}
        render={({ handleSubmit }) => (
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">Default Label</span>
              <div className="form__form-group-field">
                <Field
                  name="defaultInput"
                  component="input"
                  type="text"
                  placeholder="Default Input"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Disabled Field</span>
              <div className="form__form-group-field">
                <Field
                  name="disableInput"
                  component="input"
                  type="text"
                  placeholder="Disabled Input"
                  disabled
                />
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="success" type="submit">
                Sign in
              </Button>
            </ButtonToolbar>
          </form>
        )}
      />
    </Modal>
  );
};

export default FomExample;
