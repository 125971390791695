import React from 'react';
import { useHistory } from 'react-router-dom';
// import PropTypes from 'prop-types';

const NotFound404 = () => {
  const history = useHistory();

  const handleOnClickGoToHome = () => {
    history.push('/');
  };
  return (
    <div>
      <h1>NotFound404 page</h1>
      <p>
        <button onClick={handleOnClickGoToHome} type="button">
          go to home page
        </button>
      </p>
    </div>
  );
};

export default NotFound404;
